<template>
  <mobile-screen
    :header="true"
    screen-class="icon-app1 profile-screen delegates-screen gray-bg"
  >
    <template v-slot:header>
      <top-header-menu-wrapper menu-class="icon-hea1">
        <template v-slot:left>
          <router-link
            :to="{
              name: backLinkName
            }"
          >
            <icon icon="#cx-hea1-arrow-left" />
          </router-link>
        </template>
        <div class="component-title">
          {{ displayLabelName("profile.delegate.permission") }}
        </div>
        <template v-slot:right>
          <button v-if="activePermissionId" @click="saveDelegate()">
            <icon icon="#cx-hea1-save" />
          </button>
        </template>
      </top-header-menu-wrapper>
    </template>

    <ul
      v-if="permissions && permissions.length"
      class="clebex-item-section overflow-hidden"
    >
      <li
        class="clebex-item-section-item"
        :class="collapse.permission ? 'collapsed' : 'expanded'"
      >
        <div class="clebex-item-content-wrapper">
          <div class="clebex-section-input">
            <label class="clebex-section-input-label larger-label">
              {{ displayLabelName("profile.delegate.permission") }}
            </label>
          </div>
          <span
            class="follow-up-icons cursor-pointer"
            @click="
              collapse.permission
                ? (collapse.permission = false)
                : (collapse.permission = true)
            "
          >
            <span class="follow-up-icon-item collapsable"
              ><icon icon="#cx-app1-arrow-right-12x12" width="12" height="12"
            /></span>
          </span>
        </div>
      </li>
      <section :class="collapse.permission ? 'collapsed' : 'expanded ten'">
        <li
          v-for="availablePermission in permissions.filter(el => el.available)"
          :key="availablePermission.id"
          class="clebex-item-section-item no-bottom-border light"
        >
          <div
            class="clebex-item-content-wrapper cursor-pointer"
            @click="activePermissionId = availablePermission.id"
          >
            <div class="clebex-section-input">
              <label class="clebex-section-input-label cursor-pointer">{{
                availablePermission.translations.find(
                  el => el.locale === locale
                )
                  ? availablePermission.translations.find(
                      el => el.locale === locale
                    ).name
                  : availablePermission.name
              }}</label>
            </div>
            <span
              v-if="activePermissionId === availablePermission.id"
              class="follow-up-icons"
            >
              <span class="follow-up-icon-item">
                <div class="svg-icon">
                  <svg
                    width="12"
                    height="12"
                    style="width: 12px; height: 12px;"
                  >
                    <use xlink:href="#cx-app1-checkmark"></use>
                  </svg>
                </div>
              </span>
            </span>
          </div>
        </li>
        <section
          v-for="unavailablePermission in permissions.filter(
            el => !el.available
          )"
          :key="unavailablePermission.id"
          class="not-allowed"
        >
          <Popper
            hover
            class="popper-warning w-100"
            :content="
              missingDependencies(unavailablePermission.missingDependencies)
            "
          >
            <li
              class="clebex-item-section-item disabled no-bottom-border light"
            >
              <div class="clebex-item-content-wrapper">
                <div class="clebex-section-input">
                  <label class="clebex-section-input-label">{{
                    unavailablePermission.translations.find(
                      el => el.locale === locale
                    )
                      ? unavailablePermission.translations.find(
                          el => el.locale === locale
                        ).name
                      : availablePermission.name
                  }}</label>
                </div>
              </div>
            </li>
          </Popper>
        </section>
      </section>
    </ul>
    <ul class="clebex-item-section">
      <li class="clebex-item-section-item">
        <div class="clebex-item-content-wrapper">
          <dl class="clebex-item-dl justify-start">
            <dt class="clebex-item-dt">
              <div class="checkbox slide">
                <input id="permanently" type="checkbox" v-model="permanently" />
                <label for="permanently"></label>
              </div>
            </dt>
            <dd class="clebex-item-dd justify-start">
              {{ displayLabelName("profile.delegate.permanently") }}
            </dd>
          </dl>
        </div>
      </li>
    </ul>
    <ul v-if="!permanently" class="clebex-item-section">
      <li class="clebex-item-section-item">
        <div class="clebex-item-content-wrapper">
          <ul class="clebex-item-content-range">
            <li class="clebex-item-range from">
              <button class="clebex-item-range-cta">
                {{ displayDate(selectedDateFrom) }}
              </button>
            </li>
            <li class="clebex-item-range separator">
              <icon icon="#cx-app1-arrow-right-12x12" width="12" height="12" />
            </li>
            <li class="clebex-item-range to">
              <button class="clebex-item-range-cta">
                {{ displayDate(selectedDateTo) }}
              </button>
            </li>
          </ul>
        </div>
      </li>
      <li class="clebex-item-section-item overflow-hidden">
        <div class="clebex-item-content-wrapper">
          <select-date-range
            :selectDateFn="selectDate"
            :show-date-picker="true"
            :disablePreviousDays="true"
            :dates-to-watch="[selectedDateFrom, selectedDateTo]"
            :selected-dates="[selectedDateFrom, selectedDateTo]"
          />
        </div>
      </li>
    </ul>
  </mobile-screen>
  <router-view />
</template>

<script>
import SelectDateRange from "@/components/global/SelectDateRange";
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import { mapActions, mapState, mapGetters } from "vuex";
import { DateTime } from "luxon";
import { formatDate } from "@/services/helpers";
import { getLang } from "@/services/http-service";
import Popper from "vue3-popper";

export default {
  name: "ProfileMyDelegatesAddPermission",
  components: {
    MobileScreen,
    TopHeaderMenuWrapper,
    SelectDateRange,
    Popper
  },
  created() {
    this.getDelegatePermissions(this.userId);
  },
  data() {
    return {
      collapse: {},
      permanently: false,
      activePermissionId: null,
      selectedDateFrom: false,
      selectedDateTo: false
    };
  },
  computed: {
    ...mapGetters("settings", ["globalDateFormat"]),
    ...mapState("delegates", ["permissions"]),
    userId() {
      return this.$route.params.userId;
    },
    locale() {
      return getLang();
    }
  },
  methods: {
    ...mapActions("delegates", ["getDelegatePermissions", "setDelegation"]),
    saveDelegate() {
      const postObject = {
        permission_id: this.activePermissionId,
        delegate_id: this.userId,
        datetime_from: this.setDate(this.selectedDateFrom),
        datetime_to: this.setDate(this.selectedDateTo)
      };
      this.setDelegation(postObject);
    },
    selectDate(dates) {
      if (dates[0]) {
        this.selectedDateFrom = DateTime.fromISO(dates[0].eventDate).toFormat(
          "yyyy-dd-MM"
        );
      } else {
        this.selectedDateFrom = null;
      }
      if (dates[1]) {
        this.selectedDateTo = DateTime.fromISO(dates[1].eventDate).toFormat(
          "yyyy-dd-MM"
        );
      } else {
        this.selectedDateTo = null;
      }
    },
    displayDate(date) {
      if (!date) {
        return this.displayLabelName("global", "calendar", "select-date");
      }
      return formatDate(
        DateTime.fromFormat(date, "yyyy-dd-MM").toFormat("yyyy-MM-dd"),
        this.globalDateFormat
      );
    },
    setDate(date) {
      if (!date) {
        return null;
      }
      return DateTime.fromFormat(date, "yyyy-dd-MM").toFormat("yyyy-MM-dd");
    },
    missingDependencies(missingDependencies) {
      if (missingDependencies && missingDependencies.length) {
        let missingDependenciesString = "";
        missingDependencies.forEach(element => {
          const dependencyString = element.translations.find(
            el => el.locale === this.locale
          )
            ? element.translations.find(el => el.locale === this.locale).name
            : element.name;
          if (missingDependenciesString) {
            missingDependenciesString = `${missingDependenciesString}, `;
          }
          missingDependenciesString = `${missingDependenciesString}${dependencyString}`;
        });
        return `${this.displayLabelName(
          "profile.delegate.missing-dependencies"
        )}: ${missingDependenciesString}`;
      }
      return "";
    }
  },
  watch: {
    userId(val) {
      this.activePermissionId = null;
      this.$store.commit("delegates/setPermissions", null, {
        root: true
      });
      if (val) {
        this.getDelegatePermissions(val);
      }
    }
  },
  props: {
    backLinkName: {
      type: String,
      required: true
    }
  },
  beforeUnmount() {
    this.$store.commit("delegates/setPermissions", null, {
      root: true
    });
  }
};
</script>
